// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./utils/fonts/SBBWeb-Roman.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./utils/fonts/SBBWeb-Roman.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./utils/fonts/SBBWeb-Roman.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./utils/fonts/SBBWeb-Bold.eot");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./utils/fonts/SBBWeb-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./utils/fonts/SBBWeb-Bold.woff2");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:SBBWeb Roman;src:url(${___CSS_LOADER_URL_REPLACEMENT_0___});src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff"),url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2");font-weight:normal;font-style:normal}@font-face{font-family:SBBWeb Bold;src:url(${___CSS_LOADER_URL_REPLACEMENT_3___});src:url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff"),url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff2");font-weight:normal;font-style:normal}#root{position:absolute;width:100%;height:100%}body{padding:0;margin:0;overflow:hidden}.tm-w-m .wkp-footer .rs-mouse-position,.tm-w-xs .wkp-footer .rs-mouse-position,.tm-w-s .wkp-footer .rs-mouse-position{display:none}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AACE,WACE,wBAAA,CACA,2CAAA,CACA,kHACE,CAEF,kBAAA,CACA,iBAAA,CAPF,WACE,uBAAA,CACA,2CAAA,CACA,kHACE,CAEF,kBAAA,CACA,iBAAA,CAQJ,MACE,iBAAA,CACA,UAAA,CACA,WAAA,CAGF,KACE,SAAA,CACA,QAAA,CACA,eAAA,CAQI,sHACE,YAAA","sourcesContent":["@mixin loadFont($fontFamily, $path) {\n  @font-face {\n    font-family: #{$fontFamily};\n    src: url('utils/fonts/#{$path}.eot');\n    src:\n      url('utils/fonts/#{$path}.woff') format('woff'),\n      url('utils/fonts/#{$path}.woff2') format('woff2');\n    font-weight: normal;\n    font-style: normal;\n  }\n}\n\n@include loadFont('SBBWeb Roman', 'SBBWeb-Roman');\n@include loadFont('SBBWeb Bold', 'SBBWeb-Bold');\n\n\n#root {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n}\n\nbody {\n  padding: 0;\n  margin: 0;\n  overflow: hidden;\n}\n\n\n  .tm-w-m,\n  .tm-w-xs,\n  .tm-w-s {\n    .wkp-footer {\n      .rs-mouse-position {\n        display: none;\n      }\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
