import React from "react";
import { Paper, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router";
import SBBSwitch from "trafimage-maps/es/components/SBBSwitch";
import CONF from "../config";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    display: "flex",
    padding: "0 8px",
    height: "min-content",

    "& .MuiSwitch-root": { margin: "4px 0" },
    [theme.breakpoints.down("sm")]: {
      right: "unset",
      left: 0,
      top: 56,
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "5px 8px",
    },
    [theme.breakpoints.up("sm")]: {
      gap: 5,
      scale: 0.9,
      alignItems: "center",
      bottom: 3,
      right: 470,
      "& .MuiSwitch-root": { scale: 0.7 },
    },
    [theme.breakpoints.between(769, "lg")]: {
      right: 428,
    },
  },
  title: {
    [theme.breakpoints.between("sm", 1060)]: {
      display: "none",
    },
  },
}));

/**
 * [TRAFKLEIN-692] Component that adds a toogle on stag and dev to be able to switch easily the maps api url
 */
function DebugStyle() {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTest = /test\.geops\.io/.test(CONF.vectorTilesUrl);

  return (
    <Paper
      className={`${classes.root}`}
      data-cy="debug-style"
      elevation={isMobile ? 3 : 0}
      square
      title="Kartendaten wechseln"
    >
      <Typography className={classes.title}>Kartendaten:</Typography>
      <Grid
        component="label"
        container
        alignItems="center"
        spacing={isMobile ? 1 : 0}
      >
        <Grid item>
          <Typography>{!isTest ? <b>Prod</b> : "Prod"}</Typography>
        </Grid>
        <Grid item>
          <SBBSwitch
            checked={isTest}
            onChange={() => {
              const searchParams = new URLSearchParams(location.search);
              if (!isTest) {
                searchParams.set("tiles", "test");
              } else {
                searchParams.set("tiles", "prod");
              }
              window.location.search = searchParams.toString();
              navigate(location.href);
            }}
          />
        </Grid>
        <Grid item>
          <Typography>{isTest ? <b>Test</b> : "Test"}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default DebugStyle;
