import wkpTopics from "trafimage-maps/es/config/topics";
import CONF from "../config";

const internAccessibleTopics = Object.values(CONF.topicPermissions).flat();
export const isInternTopic = (name) => internAccessibleTopics.includes(name);
export const isPublicTopic = (name) => {
  // Allow behig topic even though it is removed to redirect to handicap.
  return wkpTopics.wkp.find((publicTopic) => publicTopic.key === name);
};

const isTopic = (id) => {
  return isPublicTopic(id) || isInternTopic(id);
};

export default isTopic;
