import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useLocation, useParams } from "react-router";
import Helmet from "react-helmet";
import { getTrackingLaunchScriptSrc } from "trafimage-maps/es/utils/trackingUtils";

// We load the translations here to avoid pollute the WebComponent with this seo stuff.
import de from "trafimage-maps/es/lang/de.json";
import fr from "trafimage-maps/es/lang/fr.json";
import it from "trafimage-maps/es/lang/it.json";
import en from "trafimage-maps/es/lang/en.json";

const translations = { de, fr, it, en };
const title = {
  de: "Trafimage Webkarten – {topic} | SBB",
  fr: "Trafimage cartes web – {topic} | CFF",
  it: "Trafimage cartine web – {topic} | FFS",
  en: "Trafimage web map – {topic} | SBB",
};
const descr = {
  de: "Das SBB Webkartenportal vereint interaktive Karten des öffentlichen Verkehrs und zeigt Ihnen die Pünktlichkeit, Tarifverbunde, aktuelle Bauprojekte usw.",
  fr: "Le portail de cartes web des CFF combine des cartes interactives des transports publics et vous montre la ponctualité, les communautés tarifaires, les projets de construction actuels, etc.",
  it: "Il portale delle cartine web delle FFS combina mappe interattive del trasporto pubblico e mostra la puntualità, comunità tariffarie, i progetti di costruzione in corso, ecc.",
  en: "The SBB web map portal combines interactive maps of public transport and shows you punctuality, fare networks, current construction projects, etc.",
};

const trackingScriptSrc = getTrackingLaunchScriptSrc();
/**
 * This component defined some additionnal tags for seo.
 */
function Head({ topics }) {
  const location = useLocation();
  const { topic } = useParams();
  const { lang, layers } = Object.fromEntries(
    new URLSearchParams(location.search),
  );

  const manifest = useMemo(() => {
    let url = ``;
    if (topic === "ch.sbb.funkmesswagen" && layers) {
      url += `${layers}.`;
    }
    url += `manifest.json`;
    return url;
  }, [topic, layers]);

  if (!topic || !topics || !topics.length || !translations || !lang) {
    return null;
  }

  const selected = topics.find((topi) => topi.key === topic) || {};
  const translated = translations[lang][selected.name || selected.key] || "";

  return (
    <>
      <Helmet>
        <html lang={lang} />
        <title>{title[lang].replace("{topic}", translated)}</title>
        <link
          rel="manifest"
          href={`${window.location.protocol}//${window.location.host}/${manifest}`}
        />
        <meta name="description" content={descr[lang]} />
        {trackingScriptSrc && (
          <script type="text/javascript" src={trackingScriptSrc} />
        )}
      </Helmet>
      <h1 style={{ display: "none" }}>{translated}</h1>
    </>
  );
}

Head.propTypes = {
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
    }),
  ),
};

Head.defaultProps = {
  topics: [],
};

export default Head;
