const env = process.env.REACT_APP_ENV;
const params = new URL(window.location.href).searchParams;
const isIframe = window !== window.parent;
const disableCookies = params.get("disableCookies");

// We allow this tiles parameter only on stag because it's a convenient parameter for client
let tilesEnvUrl = null;
const tiles = params.get("tiles");
if ((env === "stag" || env === "dev") && tiles) {
  if (tiles === "test") {
    tilesEnvUrl = "https://maps.test.geops.io";
  } else if (tiles === "prod") {
    tilesEnvUrl = "https://maps.geops.io";
  } else if (tiles === "dev") {
    tilesEnvUrl = "https://maps.style-dev.geops.io";
  }
}

const appBaseUrl = params.get("appBaseUrl") || process.env.REACT_APP_BASE_URL;
const apiKey = params.get("apiKey") || process.env.REACT_APP_VECTOR_TILES_KEY;
const vectorTilesKey =
  params.get("apiKey") ||
  params.get("vectorTileKey") ||
  process.env.REACT_APP_VECTOR_TILES_KEY;
const cartaroUrl =
  params.get("cartaroUrl") || process.env.REACT_APP_CARTARO_URL;
const vectorTilesUrl = tilesEnvUrl || process.env.REACT_APP_VECTOR_TILES_URL;
const permissionUrl = process.env.REACT_APP_PERMISSION_URL;
let staticFilesUrl =
  params.get("staticFilesUrl") || process.env.REACT_APP_STATIC_FILES_URL;
const mapsetUrl = params.get("mapsetUrl") || process.env.REACT_APP_MAPSET_URL;
const shortenerUrl =
  params.get("shortenerUrl") || process.env.REACT_APP_SHORTENER_URL;
const drawUrl = params.get("drawUrl") || process.env.REACT_APP_DRAW_URL;
const destinationUrl =
  params.get("destinationUrl") || process.env.REACT_APP_DESTINATION_URL;
const departuresUrl =
  params.get("departuresUrl") || process.env.REACT_APP_DEPARTURES_URL;
const realtimeUrl =
  params.get("realtimeUrl") || process.env.REACT_APP_REALTIME_URL;
const searchUrl = params.get("searchUrl") || process.env.REACT_APP_SEARCH_URL;
const matomoUrl = process.env.REACT_APP_MATOMO_URL_BASE;
const matomoSiteId = process.env.REACT_APP_MATOMO_SITE_ID;
const domainConsent =
  isIframe && params.get("domainConsent")
    ? params.get("domainConsent")
    : process.env.REACT_APP_DOMAIN_CONSENT;
const domainConsentId =
  isIframe && params.get("domainConsentId")
    ? params.get("domainConsentId")
    : process.env.REACT_APP_DOMAIN_CONSENT_ID;
const embedded = params.get("embedded") || process.env.REACT_APP_EMBEDDED;
const loginUrl = `${cartaroUrl.replace(
  "/api/v1/",
  "/",
)}accounts/microsoft_sbb/login/authenticate/?next=${encodeURIComponent(
  window.location.href,
)}`;

switch (env) {
  case "stag": {
    staticFilesUrl =
      params.get("staticFilesUrl") || "//wkp2.stag.trafimage.geops.ch";
    break;
  }
  case "dev": {
    staticFilesUrl =
      params.get("staticFilesUrl") || "//wkp2.dev.trafimage.geops.ch";
    break;
  }
  default: {
    break;
  }
}

const topicPermissions = {
  // "trafimage_cartaro.topics.mobz": "ch.sbb.mobz",
  // "trafimage_cartaro.topics.mobz_what_if": "ch.sbb.mobz_what_if",
  "trafimage_cartaro.topics.immobilien": "ch.sbb.immobilien",
  sbb: [
    "ch.sbb.regionenkarte.intern",
    "ch.sbb.energie.intern",
    "ch.sbb.bps",
    "ch.sbb.aep",
    "ch.sbb.qs",
  ],
  // Sandbox topic content can change from public to private. So we keep it here.
  // , 'ch.sbb.netzkarte.sandbox'],
};

// Ordered list of topic appearance
const topicsOrder = [
  "ch.sbb.energie.intern",
  "ch.sbb.regionenkarte.intern",
  "ch.sbb.immobilien",
  "ch.sbb.bps",
  "ch.sbb.aep",
  "ch.sbb.qs",
  // We keep the mobz topics in the code for now, see https://jira.geops.com/browse/TRAFKLEIN-686
  // 'ch.sbb.mobz',
  // 'ch.sbb.mobz_what_if',
  // Sandbox topic content can change from public to private. So we keep it here.
  // 'ch.sbb.netzkarte.sandbox',
];

export default {
  env,
  appBaseUrl,
  apiKey,
  vectorTilesUrl,
  vectorTilesKey,
  cartaroUrl,
  permissionUrl,
  staticFilesUrl,
  topicPermissions,
  topicsOrder,
  mapsetUrl,
  shortenerUrl,
  drawUrl,
  disableCookies,
  domainConsent,
  domainConsentId,
  embedded,
  loginUrl,
  destinationUrl,
  departuresUrl,
  searchUrl,
  matomoUrl,
  matomoSiteId,
  realtimeUrl,
};
