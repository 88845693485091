import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import qs from "query-string";
import { transform } from "ol/proj";
import App from "./App";

/**
 * URLs of the old wkp had a slightly different structure than
 * in the new one. This function tries to convert the old url
 * to the new structure and returns a converted url.
 * [TRAFDIV-541]
 */
const convertOldToNewUrl = (hash) => {
  const [path, search] = hash.split("?");
  const topicMatch = path.match(/ch.sbb.[^?]*/);

  if (!topicMatch) {
    return null;
  }

  let newUrl = topicMatch[0];

  if (search) {
    let params = { ...qs.parse(search) };
    const x = parseFloat(params.x);
    const y = parseFloat(params.y);
    const drawId = params["wkp.draw"];

    // delete old, renamed or incompatible keys
    ["display_srs", "wkp.draw"].forEach((k) => delete params[k]);

    // convert old parameters
    if (drawId) {
      params["draw.id"] = drawId;
    }

    if (x && y) {
      try {
        const newCenter = transform([x, y], "EPSG:21781", "EPSG:3857");
        params = { ...params, x: newCenter[0], y: newCenter[1] };
      } catch {
        // forget x and y
      }
    }

    newUrl = `${newUrl}?${qs.stringify(params)}`;
  }

  return newUrl;
};

function RedirectOldToNew() {
  if (window.location.hash) {
    const newUrl = convertOldToNewUrl(window.location.hash);
    if (newUrl) {
      return <Navigate to={newUrl} />;
    }
  }

  return <Navigate to={`/ch.sbb.netzkarte${window.location.search || ""}`} />;
}

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<RedirectOldToNew />} />
        <Route
          exact
          path="/ch.sbb.behig"
          element={
            <Navigate to={`/ch.sbb.handicap${window.location.search || ""}`} />
          }
        />
        <Route exact path="/:topic" element={<App />} />
        <Route exact path="/*" element={<RedirectOldToNew />} />
      </Routes>
    </Router>
  );
}

export default React.memo(AppRouter);
