import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import React from "react";
import AppRouter from "./AppRouter";
import registerServiceWorker from "./serviceWorker";

if (process.env.REACT_APP_PWA === "active") {
  registerServiceWorker();
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<AppRouter />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
