import CONF from "../config";
import { isInternTopic } from "./isTopic";

// Load a topic configuration.
const loadChunk = async (name) => {
  if (!name || !isInternTopic(name)) {
    return;
  }
  try {
    // eslint-disable-next-line consistent-return, no-return-await
    return await import(
      /* webpackChunkName: "[request]" */
      `../topics/${name}`
    );
  } catch {
    // eslint-disable-next-line no-console
    console.error(`Can't load topic ${name}`);
    // eslint-disable-next-line consistent-return, no-return-await
    return null;
  }
};

// Get the list of topics available for these permissions.
const getTopicsByPermissions = async (permissions = []) => {
  const lazyTopics = [];
  const accessTopics = permissions
    .map((p) => CONF.topicPermissions[p])
    .flat()
    .filter((t) => t); // remove `undefined` values

  // Reorder topics
  const orderedAccessTopics = [];
  CONF.topicsOrder.forEach((topicName) => {
    const topicTodisplay = accessTopics.find((top) => topicName === top);
    if (topicTodisplay) {
      orderedAccessTopics.push(topicTodisplay);
    }
  });

  if (orderedAccessTopics.length !== accessTopics.length) {
    // eslint-disable-next-line no-console
    console.error("You forgot to add the topic in the topics order list");
  }

  for (let i = 0; i < orderedAccessTopics.length; i += 1) {
    // eslint-disable-next-line
    const loadedTopic = await loadChunk(orderedAccessTopics[i]);
    if (loadedTopic && loadedTopic.default) {
      lazyTopics.push(loadedTopic.default);
    }
  }
  return lazyTopics;
};

export default getTopicsByPermissions;
