import WebComponent from "trafimage-maps/es/WebComponent";

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import i18next from "i18next";
import wkpTopics from "trafimage-maps/es/config/topics";
import { ThemeProvider } from "@mui/material";
import tmTheme from "trafimage-maps/es/themes/default";
import applPermalinkVisiblity from "trafimage-maps/es/utils/applyPermalinkVisibility";
import styles from "./App.scss";
import CONF from "./config";
import Head from "./Head";
import DebugStyle from "./components/DebugStyle";
import isTopic, { isInternTopic } from "./utils/isTopic";
import getTopicsByPermissions from "./utils/getTopicsByPermissions";

// Get the permissions.
const loadPermissions = async () => {
  const url = `${CONF.cartaroUrl}permission_topics/`;
  const permissions = await fetch(url, { credentials: "include" })
    .then((response) => response.json())
    .catch(() => {});
  return permissions;
};

function App() {
  const navigate = useNavigate();
  const { topic } = useParams();
  const [topics, setTopics] = useState([]);
  const [permInfos, setPermInfos] = useState();

  const history = useMemo(() => {
    return {
      replace: (path) => {
        navigate(path);
      },
    };
  }, [navigate]);

  // On mount:
  //  - load permissions
  //  - lazy load topics availables for this permission
  //  - redirect to login page if necessary
  //  - load trafimage-maps with topics
  useEffect(() => {
    // If the topic doesn't exist, we redirect to the home page.
    if (!isTopic(topic)) {
      navigate("/");
      return;
    }

    const loadTopics = async () => {
      const permissionInfos = await loadPermissions();

      // if the user is not logged in but it's an internal topic, we redirect to login page.
      if (!permissionInfos?.user && isInternTopic(topic)) {
        window.location.href = CONF.loginUrl;
        return;
      }

      // If the user is logged in, we load public + internal topics
      if (permissionInfos) {
        const permissionTopics = await getTopicsByPermissions(
          permissionInfos.permissions,
        );

        if (permissionTopics?.length) {
          const sandboxIdx = permissionTopics.findIndex(
            (t) => t.key === "ch.sbb.netzkarte.sandbox",
          );
          permissionTopics.push(permissionTopics.splice(sandboxIdx, 1)[0]);

          // We load all the internal topic translations here to have the topic name translated
          // When we load a public topic first.
          permissionTopics.forEach((pTopic) => {
            if (pTopic.translations) {
              Object.entries(pTopic.translations).forEach(([lang, trans]) => {
                i18next.addResourceBundle(lang, "translation", trans);
              });
            }

            // without this the text in topic menu header is wrong on page load
            applPermalinkVisiblity(
              pTopic.layers,
              (pathname) => pathname?.indexOf(`/${pTopic.key}`) !== -1,
            );
          });

          setPermInfos(permissionInfos);
          setTopics([...wkpTopics.wkp, ...permissionTopics]);
          return;
        }
      }

      // If the user is (or is not) logged in and has no internal topics, we load the default topics.
      setTopics(wkpTopics.wkp);
    };
    loadTopics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <style>{styles}</style>
      <WebComponent
        appName="maps.trafimage.ch" // important to avoid default loading of wkp topics who messed up the permalink parameters.
        enableTracking="true"
        history={history}
        topics={topics}
        permissionInfos={permInfos}
        activeTopicKey={topic}
        apiKey={CONF.apiKey}
        loginUrl={CONF.loginUrl}
        cartaroUrl={CONF.cartaroUrl}
        appBaseUrl={CONF.appBaseUrl}
        vectorTilesKey={CONF.vectorTilesKey}
        vectorTilesUrl={CONF.vectorTilesUrl}
        permissionUrl={CONF.permissionUrl}
        staticFilesUrl={CONF.staticFilesUrl}
        mapsetUrl={CONF.mapsetUrl}
        shortenerUrl={CONF.shortenerUrl}
        drawUrl={CONF.drawUrl}
        disableCookies={CONF.disableCookies}
        domainConsent={CONF.domainConsent}
        domainConsentId={CONF.domainConsentId}
        embedded={CONF.embedded}
        destinationUrl={CONF.destinationUrl}
        departuresUrl={CONF.departuresUrl}
        matomoUrl={CONF.matomoUrl}
        matomoSiteId={CONF.matomoSiteId}
        searchUrl={CONF.searchUrl}
        realtimeUrl={CONF.realtimeUrl}
        width="100%"
        height="100%"
      >
        {(CONF.env === "dev" || CONF.env === "stag") &&
          new URL(window.location.href).searchParams.get("tiles") !== "dev" && (
            <ThemeProvider theme={tmTheme}>
              <DebugStyle />
            </ThemeProvider>
          )}
      </WebComponent>
      <Head topics={topics} />
    </>
  );
}

export default React.memo(App);
